<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">订单号：</span>
        <el-input v-model="search.order" placeholder="请输入订单号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input v-model="search.username" placeholder="请输入账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">游戏名称：</span>
        <el-input v-model="search.game_name" placeholder="请输入游戏名称" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">平台：</span>
        <el-select v-model="search.pt">
          <el-option key="" value="" label="全部">全部</el-option>
          <el-option v-for="item in ptList" :key="item" :value="item.pt" :label="item.name"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="order" width="250" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="username" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="pt" label="平台" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{getPtName(scope.row.pt)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="game_name" label="游戏名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="price" label="下注金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="win_price" label="中奖金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="" label="输赢" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span style="color :green" v-if="scope.row.win_price > 0">赢</span>
          <span v-if="scope.row.win_price == scope.row.price">平</span>
          <span style="color :red" v-if="scope.row.win_price == 0">输</span>
        </template>
      </el-table-column>
      <el-table-column prop="tz_time" label="下注时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="js_time" label="结算时间" align="center" :show-overflow-tooltip="true"></el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
  </div>
</template>

<script>
import page from "@/components/page";
import {getList} from "@/api/game/tz.js"
import {getGamePtList} from "@/api/game/pt"
export default {
  name: "gameList",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 20 },
      search: {
        order: "", // 订单号
        pt: null,
        game_name:"",
        username:"",
      },
      tableData:[],
      ptList:[],
    };
  },
  mounted() {
    this.getList()
    this.getPtList()
  },
  methods: {
    getList(){
      var data = [{key:"row",val:this.pager.rows},{key:"page",val:this.pager.page}]
      for(var key in this.search){
        data.push({
          key:key,
          val:this.search[key]
        })
      }
      getList(data).then((res)=>{
        if(res.code == 0){
          this.tableData = res.data.list;
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    getPtList(){
      getGamePtList().then((res)=>{
        if (res.code == 0){
          this.ptList = res.data;
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    getPtName(pt){
      for(var index in this.ptList){
        if (this.ptList[index]['pt'] === pt){
          return this.ptList[index]["name"]
        }
      }
      return pt
    }
  },
};
</script>

<style scoped>
</style>